import React,{useState,useEffect} from 'react'
import {Container,Grid,List,ListItem,ListItemText,Button,TextField} from '@material-ui/core'
import Select from './Select';
import {MdKeyboardArrowUp,MdKeyboardArrowDown,MdDone} from 'react-icons/md';
import Slide from '@material-ui/core/Slide';

// import SelectComponent from './SelectComponent'
const Pagina9=({prevStep,nextStep,values,handleClick,setInput}) => {


  const [idx,setIdx]=useState(values.escala)
  console.log(values);
  const inputFun=(lj) => {
    console.log(lj);
    setIdx(lj)
    setInput('escala',lj)
  }
  //   const { firstName, lastName, email, username, country, levelOfEducation } = values
  const Continue=e => {
    if(values.escala==-1) {
      alert('Selecione uma escala')
      return
    }
    console.log(e)
    setInput('animation','up')
    e.preventDefault();
    nextStep();
  }

  const Previous=e => {
    // values.animation = 'down'
    setInput('animation','down')
    e.preventDefault();
    prevStep();
  }
  const valores=[
    {valor: 0},
    {valor: 1},
    {valor: 2},
    {valor: 3},
    {valor: 4},
    {valor: 5},
    {valor: 6},
    {valor: 7},
    {valor: 8},
    {valor: 9},
    {valor: 10},
  ]

  return (

    <Slide direction={values.animation} in={true}>
      <div className="container">
        <div className="box">

          <div className="titulo">
            Em uma escala de 0 a 10 quanto você está satisfeito com o Barbosa?
          </div>
          <div>

            {values.window<800? <div className="labels-mobile">
              <div className="label-text">Em uma escala entre 0 e 10 sendo 0 pouco satisfeito e 10 muito satisfeito, responda:</div>
              {/* <div className="label-right">Muito satisfeito</div> */}
            </div>:null
            }
            <div className={values.window<=500? "escala-grid":(values.window>500&&values.window<=800)? "escala-tablet":"escala"}>
              {valores.map((e,i) => {
                return <div className={i!=idx? "button-escala":"button-escala select-escala"} value={e.valor} onClick={() => inputFun(i)} key={i} >{e.valor}</div>
                // return <div value={e.valor} onClick={() => handleClick(i)} key={i} className="button-escala">{e.valor}</div>
              })}
            </div>
            {values.window>800? <div className="labels">
              <div className="label-left">Pouco satisfeito</div>
              <div className="label-right">Muito satisfeito</div>
            </div>:null
            }
            {/* {values.window > 800 ? <div className="labels">
              <div className="label-left">Pouco satisfeito</div>
              <div className="label-right">Muito satisfeito</div>
            </div> : <div className="labels-mobile">
              <div className="label-left">Pouco satisfeito - 0 a 3</div>
              <div className="label-right">Muito satisfeito - 8 a 10	</div>
            </div>
            } */}

            <button onClick={Continue} className="btn">Ok <MdDone className="iconStyles" /></button>
            <div className="subbuttons">
              <button onClick={Previous} className="up"><MdKeyboardArrowUp color="#fff" size={25} className="iconStyles-2" /></button>
              <div className="middle"></div>
              <button onClick={Continue} className="down"><MdKeyboardArrowDown color="#fff" size={25} /></button>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  )
}

const styles={
  select: {
    width: "100%"
  },
  subbuttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30%",
  },
  middle: {
    width: "0.3%",
    backgroundColor: "#B30E00"
  },
  up: {
    borderTopLeftRadius: "7%",
    borderBottomLeftRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  down: {
    borderTopRightRadius: "7%",
    borderBottomRightRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  box: {
    marginBottom: "17%",
    // marginLeft: "17%",
    width: "50%",
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  escala: {
    // padding:"5%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "spaceBetween"
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Montserrat', sans-serif"
  },

  titulo: {
    fontSize: "20pt",
    marginBottom: "3%",
    marginRight: "11%",
    color: '#fff'
  },
  logo: {
    maxWidth: "200px",
    maxHeight: "150px"
  },
  texto: {
    font: "normal normal medium 20px/28px Montserrat",
    color: "#FFFFFF",
    // letterSpacing: "1px",
    textAlign: "center",
    marginTop: "3%",
    color: "#fff",
    fontSize: "1rem",
    opacity: "0.65"
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "2%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  },
  btni: {
    "&.active": {
      background: 'black',
    },
    hover: "red",
    width: "3rem",
    height: "3rem",
    marginTop: "2%",
    color: "#fff",
    fontSize: "17pt",
    border: "1px solid #A6ACCD",
    margin: "0.5%"
  },


}


export default Pagina9