import React from 'react'
import logo from './../assets/img/LogoBarbosa_branco.svg'
import '../assets/estilos/pags.css'

// import Button from './Button'

const Pagina1 = ({ prevStep, nextStep, values }) => {

	console.log(values);
	const Continue = e => {
		console.log(e)
		e.preventDefault();
		nextStep();
	}

	const Previous = e => {
		e.preventDefault();
		prevStep();
	}

	return (
		<div className="containerHome">
			<div className="boxHome">
				<img src={logo} />

				<div className="tituloHome">
					Oi como vai?
                          </div>
				<div className="textoHome">
					<div className="textocimaHome">
						{values.textoInit}
					</div>
					{/* <div className="textocimaHome">
						Queremos saber como você se sente em relação ao seu trabalho e a empresa.
                    </div>
					<div>
						Sua opinião nos ajudará a tornar um lugar melhor para trabalhar.
                    </div> */}
				</div>
				<div>
					{/* <button onClick={Continue} className="btn">Ok, vamos lá</button> */}
					{!values.vencido ?
					 <button onClick={Continue} className="btnHome"><div>Ok, vamos lá</div></button>
					:
					null}
          {/* <button onClick={Continue} className="btnHome"><div>Ok, vamos lá</div></button> */}
					{/* <Button onClick={Continue} className="btnHome">Ok, vamos lá</Button> */}
				</div>
			</div>
		</div>
	)
}

const styles = {
	textocima: {
		marginBottom: '0.3rem'
	},
	body: {
		fontFamily: "normal normal 600 24px/30px Montserrat;"
	},
	container: {
		width: "100vw",
		height: "100vh",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		fontFamily: "'Montserrat', sans-serif"
	},
	box: {
		marginBottom: "17%",
		width: "48vw",
		height: "30vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	titulo: {
		fontFamily: "'Montserrat', sans-serif",
		fontSize: "20pt",
		marginTop: "4%",
		color: '#fff'
	},

	texto: {
		font: "normal normal medium 20px/28px Montserrat",
		color: "#FFFFFF",
		letterSpacing: "1px",
		textAlign: "center",
		marginTop: "3%",
		color: "#fff",
		fontSize: "1rem",
		opacity: "0.65"
	},
	btn: {
    // font: normal normal bold 24px/30px 'Montserrat';
		width: "14rem",
		height: "3rem",
		marginTop: "15%",
		color: "#fff",
		backgroundColor: "#E9732F",
		fontSize: "17pt",
	},
	'@media (max-width: 500px)': {
		body: {
			backgroundColor: 'green'
		},
		container: {
			backgroundColor: 'red'
		}
	}
}


export default Pagina1