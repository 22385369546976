import React from 'react'
import { Container, Grid, List, ListItem, ListItemText, Button, TextField, Slide } from '@material-ui/core'

import logo from './../assets/img/LogoBarbosa_branco.svg'
import '../assets/estilos/App.css';
// import Button from './Button'
const Pagina1v2 = ({ prevStep, nextStep, values }) => {
      
      console.log(values);
      const Continue = e => {
            console.log(e)
            e.preventDefault();
            nextStep();
      }

      const Previous = e => {
            e.preventDefault();
            prevStep();
      }

      return (
      <div className="container">
        <h1>olá</h1>
      </div>
         
      )
}

const styles = {
      textocima:{
            marginBottom:'0.3rem'
      },
      body:{
            fontFamily: "normal normal 600 24px/30px Montserrat;"
      },
      container: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "'Montserrat', sans-serif"
      },
      box: {
            marginBottom: "17%",
            width: "48vw",
            height: "30vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
      },
      titulo: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "20pt",
            marginTop: "4%",
            color: '#fff'
      },
      
      texto: {
            font: "normal normal medium 20px/28px Montserrat",
            color: "#FFFFFF",
            letterSpacing: "1px",
            textAlign: "center",
            marginTop: "3%",
            color: "#fff",
            fontSize:"1rem",
            opacity: "0.65"
      },
      btn:{
            width:"14rem",
            height:"3rem",
            marginTop:"15%",
            color:"#fff",
            backgroundColor:"#E9732F",
            fontSize: "17pt",
      }
}


export default Pagina1v2