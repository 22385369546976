import React from 'react'
import ReactDom from 'react-dom'

import Button from './componentes/Button'
import Home from './pages/Home'
import Teste from './pages/Teste'
ReactDom.render(
      <React.StrictMode>
            <Teste/>
      </React.StrictMode>,
      document.getElementById('root')
)