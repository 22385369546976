import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 520,
    maxWidth: 520,
  },
  btn:{
    width:"6rem",
    height:"3rem",
    marginTop:"20%",
    color:"#fff",
    backgroundColor:"#E9732F",
    fontSize: "17pt",
},
  input:{
      color: "#FFFFFF",
      opacity: "0.65",
      fontSize:"1.5rem"
  },
  selected:{
      marginTop:"10%",
      color:"#FFF",
      opacity: "0.65",
      fontSize:"1.5rem"
  },
  item:{
        backgroundColor:"#fff",
        
  }
}));

export default function ControlledOpenSelect(props) {


  console.log(props);
  const { valores } = props
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <div>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.input} id="demo-controlled-open-select-label">Selecione Uma Opção</InputLabel>
        <Select
          className={classes.selected}
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          onChange={handleChange}
        >
          <MenuItem value="" className={classes.item}><em>Nada</em></MenuItem>
          {valores.map((e, i) => {
         return <MenuItem value={e.valor} key={i} className={classes.item}>{e.text}</MenuItem>;
        })}
       
        </Select>
      </FormControl>
    </div>
      
    </div>
  );
}