import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdDone } from 'react-icons/md';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 520,
    maxWidth: 520,
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "20%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  },
  input: {
    color: "#FFFFFF",
    opacity: "0.65",
    fontSize: "1.5rem"
  },
  selected: {
    marginTop: "10%",
    color: "#FFF",
    opacity: "0.65",
    fontSize: "1.5rem"
  },
  item: {
    backgroundColor: "#fff",

  }
}));
const Pagina4 = ({ prevStep, nextStep, values, handleChange, setInput }) => {
  console.log(values);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);

  const inputFun = (lj) => {
    console.log(lj);
    setInput('cargo', lj)
  }
  const Continue = e => {
    if(values.cargo == ''){
      alert('O campo cargo está vazio')
      return
    }
    console.log(e)
    values.animation = 'up'
    setInput('animation', 'up')

    e.preventDefault();
    nextStep();
  }
  const Previous = e => {
    values.animation = 'down'
    setInput('animation', 'down')

    e.preventDefault();
    prevStep();
  }
  function handleInput(isFocus){
    setTimeout(()=>{
      setIsFocus(isFocus);
    },250);
  }
  
  const valores = [
    { valor: "liderança", text: 'Exerço cargo de liderança' },
    { valor: "nLiderança", text: 'Não Exerço cargo de liderança' },
  ]

  return (
    <Slide direction={values.animation} in={true}>
    <div className="container">
        <div className="box">
          <div className="titulo">
          Qual o seu cargo?
            </div>
          <input 
          
          type="text"
          className={values.cargo == '' ? 'mySelect': 'mySelect select-active'}
          autocomplete="off"
          placeholder="Selecione um cargo"
          onFocus={()=>{handleInput(true)}}
          onBlur={()=>{handleInput(false)}}
          value={values.cargo}
          onChange={setInput}
          readonly="true" />
          {
            isFocus === true?
            <div className="options">
              {valores.map((e, i) => {
                return <div className={e.text==values.cargo?'item item-active':'item'} key={i} onClick={() => inputFun(e.text)}>{e.text}</div>
              })}
            </div>
            :
            null
          }
          <button onClick={Continue} className="btn">Ok <MdDone className="iconStyles" /></button>
          
          
          <div className="subbuttons">
            <button onClick={Previous} className="up"><MdKeyboardArrowUp color="#fff" size={25} /></button>
            <div className="middle"></div>
            <button onClick={Continue} className="down"><MdKeyboardArrowDown color="#fff" size={25} /></button>
          </div>
        </div>
      </div>
    </Slide>
    // <div style={styles.container}>
    //   <div style={styles.box}>
    //     {/* <img src={logo} style={styles.logo} /> */}

    //     <div style={styles.titulo}>
    //       Qual o seu cargo ou posição?
    //                      </div>
    //     <div>
    //       <FormControl className={classes.formControl}>
    //         <InputLabel className={classes.input} id="demo-controlled-open-select-label">Selecione Uma Opção</InputLabel>
    //         <Select
    //           className={classes.selected}
    //           labelId="demo-controlled-open-select-label"
    //           id="demo-controlled-open-select"
    //           open={open}
    //           onClose={handleClose}
    //           onOpen={handleOpen}
    //           onChange={handleChange('cargo')}
    //           value={values.cargo}

    //         // onChange={() => update(valor)}
    //         >
    //           <MenuItem value="" className={classes.item}><em>Nada</em></MenuItem>
    //           {valores.map((e, i) => {
    //             return <MenuItem value={e.valor} key={i} className={classes.item}>{e.text}</MenuItem>;
    //           })}

    //         </Select>
    //       </FormControl>
    //     </div>
    //     <Button onClick={Continue} style={styles.btn}>Ok</Button>
    //     <div style={styles.subbuttons}>
    //       <button onClick={Previous} style={styles.up}><MdKeyboardArrowUp color="#fff" size={25} /></button>
    //       <div style={styles.middle}></div>
    //       <button onClick={Continue} style={styles.down}><MdKeyboardArrowDown color="#fff" size={25} /></button>
    //     </div>
    //   </div>
    // </div>
  )
}

const styles = {
  select: {
    width: "100%"
  },
  subbuttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30%",
  },
  middle: {
    width: "0.3%",
    backgroundColor: "#B30E00"
  },
  up: {
    borderTopLeftRadius: "7%",
    borderBottomLeftRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  down: {
    borderTopRightRadius: "7%",
    borderBottomRightRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Montserrat', sans-serif"

  },
  box: {
    marginBottom: "17%",
    marginLeft: "17%",
    width: "50%",
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  titulo: {
    fontSize: "20pt",
    marginBottom: "3%",
    marginRight: "11%",
    color: '#fff'
  },
  logo: {
    maxWidth: "200px",
    maxHeight: "150px"
  },
  texto: {
    font: "normal normal medium 20px/28px Montserrat",
    color: "#FFFFFF",
    letterSpacing: "1px",
    textAlign: "center",
    marginTop: "3%",
    color: "#fff",
    fontSize: "1.1rem",
    opacity: "0.65"
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "2%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  }
}


export default Pagina4