import React from 'react'
import Slide from '@material-ui/core/Slide';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdDone } from 'react-icons/md';


const Pagina8 = ({ prevStep, nextStep, values, handleChange, setInput }) => {
  console.log(values);

  const [open, setOpen] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);

  const inputFun = (lj) => {
    console.log(lj);
    setInput('escolaridade', lj)
  }
  const Continue = e => {
    if (values.escolaridade == '') {
      alert('O campo escolaridade está vazio')
      return
    }
    console.log(e)
    values.animation = 'up'
    setInput('animation', 'up')

    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    values.animation = 'down'
    setInput('animation', 'down')
    e.preventDefault();
    prevStep();
  }
  function handleInput(isFocus) {
    setTimeout(() => {
      setIsFocus(isFocus);
    }, 250);
  }
  const valores = [
    { valor: "emi", text: 'Ensino médio incompleto' },
    { valor: "si", text: 'Superior incompleto' },
    { valor: "pi", text: 'Pós-graduação incompleto' },
    { valor: "emc", text: 'Ensino médio completo' },
    { valor: "sc", text: 'Superior completo' },
    { valor: "pc", text: 'Pós-graduação completo' },
  ]

  return (
    <Slide direction={values.animation} in={true}>
      <div className="container">
        <div className="box">
          <div className="titulo">
            Qual o seu grau de escolaridade?
    </div>
          <input
            type="text"
            className={values.escolaridade == '' ? 'mySelect' : 'mySelect select-active'}
            autocomplete="off"
            placeholder="Selecione uma opção"
            onFocus={() => { handleInput(true) }}
            onBlur={() => { handleInput(false) }}
            value={values.escolaridade}
            onChange={setInput}
            readonly="true" />
          {
            isFocus === true ?
              <div className="options">
                {valores.map((e, i) => {
                  return <div className={e.text == values.escolaridade ? 'item item-active' : 'item'} key={i} onClick={() => inputFun(e.text)}>{e.text}</div>
                })}
              </div>
              :
              null
          }
          <button onClick={Continue} className="btn">Ok <MdDone className="iconStyles" /></button>
          {/* <button onClick={Continue} className="btn">Ok <MdDone color="#fff" size={25} /></button> */}
          <div className="subbuttons">
            <button onClick={Previous} className="up"><MdKeyboardArrowUp color="#fff" size={30} className="iconStyles-2" /></button>
            <div className="middle"></div>
            <button onClick={Continue} className="down"><MdKeyboardArrowDown color="#fff" size={30} /></button>
          </div>

        </div>
      </div>
    </Slide>
  )
}

const styles = {
  select: {
    width: "100%"
  },
  subbuttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30%",
  },
  middle: {
    width: "0.3%",
    backgroundColor: "#B30E00"
  },
  up: {
    borderTopLeftRadius: "7%",
    borderBottomLeftRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  down: {
    borderTopRightRadius: "7%",
    borderBottomRightRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  box: {
    marginBottom: "17%",
    marginLeft: "17%",
    width: "50%",
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Montserrat', sans-serif"

  },

  titulo: {
    fontSize: "20pt",
    marginBottom: "3%",
    marginRight: "11%",
    color: '#fff'
  },
  logo: {
    maxWidth: "200px",
    maxHeight: "150px"
  },
  texto: {
    font: "normal normal medium 20px/28px Montserrat",
    color: "#FFFFFF",
    letterSpacing: "1px",
    textAlign: "center",
    marginTop: "3%",
    color: "#fff",
    fontSize: "1.1rem",
    opacity: "0.65"
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "2%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  }
}


export default Pagina8