import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdDone } from 'react-icons/md';
import '../assets/estilos/pags.css'
// import Select from './Select';
// import SelectComponent from './SelectComponent'
const useStyles = makeStyles((theme) => ({
  formControl: {
    color: "#fff",
    minWidth: 520,
    maxWidth: 220,
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "20%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  },
  input: {
    color: "#FFFFFF",
    opacity: "0.65",
    fontSize: "1.5rem"
  },
  selected: {
    backgroundColor: "#fff",
    marginTop: "10%",
    color: "#FFF",
    opacity: "0.65",
    fontSize: "1.5rem"
  },
  item: {
    backgroundColor: "#fff",
  }
}));

const Pagina2 = ({ prevStep, nextStep, values, handleChange, setLojat, setInput }) => {

  console.log(values)
  const classes = useStyles();
  const [isFocus, setIsFocus] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loja, setLoja] = React.useState('');

  const [direction,setDirection] = React.useState('up');
  const [show,setShow] = React.useState(true);


  const lojaFun = (lj) => {
    console.log(lj);
    setLoja(lj)
    setInput('loja', lj)
    // setLojat(lj)
    // values.loja = lj
   
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const Continue = e => {
    if(values.loja == ''){
      alert('O campo loja está vazio')
      return
    }
    console.log(values.loja);
    console.log(e)
    setInput('animation', 'up')
    
    e.preventDefault();
    nextStep();
  }
  const Previous = e => {
    values.animation = ''
    setInput('animation', 'down')
    e.preventDefault();
    prevStep();
  }
  const teste = () => {
    console.log("teste", new Date().getTime());
  }
  const valores = [
    { valor: 'Adm-Matriz-CDs', text: 'Adm Matriz/ CDs' },
    { valor: 1, text: 'Loja 1' },
    { valor: 2, text: 'Loja 2' },
    { valor: 3, text: 'Loja 3' },
    { valor: 4, text: 'Loja 4' },
    { valor: 5, text: 'Loja 5' },
    { valor: 6, text: 'Loja 6' },
    { valor: 7, text: 'Loja 7' },
    { valor: 8, text: 'Loja 8' },
    { valor: 9, text: 'Loja 9' },
    { valor: 10, text: 'Loja 10' },
    { valor: 11, text: 'Loja 11' },
    { valor: 12, text: 'Loja 12' },
    { valor: 14, text: 'Loja 14' },
    { valor: 15, text: 'Loja 15' },
    { valor: 16, text: 'Loja 16' },
    { valor: 18, text: 'Loja 18' },
    { valor: 19, text: 'Loja 19' },
    { valor: 20, text: 'Loja 20' },
    { valor: 21, text: 'Loja 21' },
    { valor: 23, text: 'Loja 23' },
    { valor: 24, text: 'Loja 24' },
    { valor: 25, text: 'Loja 25' },
    { valor: 26, text: 'Loja 26' },
    { valor: 28, text: 'Loja 28' },
    { valor: 29, text: 'Loja 29' },
    { valor: 31, text: 'Loja 31' },
    { valor: 33, text: 'Loja 33' },
    { valor: 35, text: 'Loja 35' },
    { valor: 36, text: 'Loja 36' },
    { valor: 37, text: 'Loja 37' },
    { valor: 38, text: 'Loja 38' },
    { valor: 39, text: 'Loja 39' },
  ]
  function handleClick(e) {
    e.preventDefault();
    console.log('The link was clicked.');
  }

  function handleInput(isFocus){
    setTimeout(()=>{
      setIsFocus(isFocus);
    },250);
  }

  return (
    <Slide direction={values.animation} in={show}>
      <div className="container">
        <div className="box">
          <div className="titulo">
            Primeiro de Tudo, de qual loja você é?
            </div>
          <input 
          type="text"
          className={values.loja == '' ? 'mySelect': 'mySelect select-active'}
          autocomplete="off"
          placeholder="Selecione uma loja"
          onFocus={()=>{handleInput(true)}}
          onBlur={()=>{handleInput(false)}}
          value={values.loja}
          onChange={setLojat}
          readonly="true" />
          {
            isFocus === true?
            <div className="options">
              {valores.map((e, i) => {
                return <div className={e.text==values.loja?'item item-active':'item'} key={i} onClick={() => lojaFun(e.text)}>{e.text}</div>
              })}
            </div>
            :
            null
          }
          <button onClick={Continue} className="btn">Ok <MdDone className="iconStyles" /></button>
          {/* <button onClick={Continue} className="btn">Ok <MdDone color="#fff" size={25} /></button> */}
          <div className="subbuttons">
            <button onClick={Previous} className="up"><MdKeyboardArrowUp color="#fff" size={25} /></button>
            <div className="middle"></div>
            <button onClick={Continue} className="down"><MdKeyboardArrowDown color="#fff" size={25} /></button>
          </div>
        </div>
      </div>
    </Slide>

  )
}

const styles = {
  select: {
    width: "100%"
  },
  subbuttons: {
    display: "flex",
    flexDirection: "row",
    // marginTop: "30%",
  },
  middle: {
    width: "0.3%",
    backgroundColor: "#B30E00"
  },
  up: {
    borderTopLeftRadius: "7%",
    borderBottomLeftRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  down: {
    borderTopRightRadius: "7%",
    borderBottomRightRadius: "7%",
    width: "3rem",
    height: "2.5rem",
    border: "0",
    backgroundColor: "#D12C1E"
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Montserrat', sans-serif"
  },
  box: {
    marginBottom: "17%",
    marginLeft: "17%",
    width: "50%",
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    // alignItems: "left",
  },
  titulo: {
    fontSize: "20pt",
    marginBottom: "3%",
    marginRight: "11%",
    color: '#fff'
  },
  logo: {
    maxWidth: "200px",
    maxHeight: "150px"
  },
  texto: {
    font: "normal normal medium 20px/28px Montserrat",
    color: "#FFFFFF",
    letterSpacing: "1px",
    textAlign: "center",
    marginTop: "3%",
    color: "#fff",
    fontSize: "1.1rem",
    opacity: "0.65"
  },
  btn: {
    width: "6rem",
    height: "3rem",
    marginTop: "2%",
    color: "#fff",
    backgroundColor: "#E9732F",
    fontSize: "17pt",
  }
}


export default Pagina2