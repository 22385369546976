import React, { Component, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Confirmation from './Confirmation'
import Pagina1 from './../componentes/Pagina1'
import Pagina2 from './../componentes/Pagina2'
import Pagina3 from './../componentes/Pagina3'
import Pagina4 from './../componentes/Pagina4'
import Pagina5 from './../componentes/Pagina5'
import Pagina6 from './../componentes/Pagina6'
import Pagina7 from './../componentes/Pagina7'
import Pagina8 from './../componentes/Pagina8'
import Pagina9 from './../componentes/Pagina9'
import Pagina10 from './../componentes/Pagina10'
import Perguntas from './../componentes/Perguntas'
import { api } from '../services/api';
import Pagina1v2 from './../componentes/Pagina1v2'
import { Container } from '@material-ui/core'
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group'
import Slide from '@material-ui/core/Slide';
import './home.css'

export default class Home extends Component {

  constructor() {
    super()
    const useStyles = makeStyles((theme) => ({
      root: {
        height: 180,
      },
      wrapper: {
        width: 100 + theme.spacing(2),
      },
      paper: {
        zIndex: 1,
        position: 'relative',
        margin: theme.spacing(1),
      },
      svg: {
        width: 100,
        height: 100,
      },
      polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
      },
    }));
  }

  state = {
    vencido: true,
    step: 1,
    nota: -1,
    loja: '',
    area: '',
    cargo: '',
    idade: '',
    genero: '',
    trabalho: '',
    escolaridade: '',
    escala: -1,
    data: '',
    animation: '',
    window: window.innerWidth
  }

  componentDidMount() {
    console.log('window.location.search', window.location);
    const headers = { headers: { 'Content-Type': 'application/json' } }
    const body = {
      url: 'http://barbosa.ibrs.com.br:5043/api/avaliacao/getavaliacao',
      search: window.location.search ? window.location.search : '',
      app_key: '0fmSSgUn63kQzjuDACeXA2D2',
      app_secret: 'n5nJxRfS4ydZ'
    }
    api.post('/', body, headers)
      .then(res => {
        if (!res.data.status) {
          this.setState({ vencido: true, textoInit: res.data.msg })
          return
        }
        let dataEndArr = res.data.avaliacao.dataEnd.split('/')
        let dataEnd = `${dataEndArr[1]}/${dataEndArr[0]}/${dataEndArr[2]}`
        let verifyDate = new Date(dataEnd).getTime() + (1000 * 60 * 60 * 24)
        let hoje = new Date().setHours(0, 0, 0, 0)
        if (hoje > verifyDate) {
          // alert('Essa pesquisa já foi finalizada');
          this.setState({ vencido: true, textoInit: 'Pesquisa vencida' })
        } else {
          // console.log(res.data)
          let titulo = res.data.avaliacao.texto
          this.setState({ textoInit: titulo, vencido: false })
        }


        console.log(dataEnd);
      })
  }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }
  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }
  // Handle fields change
  handleChange = input => e => {
    console.log(input);
    console.log(e);
    this.setState({ [input]: e.target.value });
    this.setState((checked) => !checked);
  }
  setLoja = input => {
    console.log(input);
    this.setState({ loja: input })
  }
  setInput = (input, value) => {
    this.setState({ [input]: value })
  }
  handleClick = e => {
    console.log(e);
    this.setState({ escala: e });
  }
  close = () => {
    window.open('', '_self', ''); //bug fix
    window.close();
  }
  render() {

    const container = {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "'Montserrat', sans-serif"
    };
    const { step } = this.state;
    const { vencido, loja, area, cargo, idade, genero, trabalho, escolaridade, escala, animation, window, textoInit, nota } = this.state;
    const values = { vencido, loja, area, cargo, idade, genero, trabalho, escolaridade, escala, animation, window, textoInit, nota }

    switch (step) {
      case 1:
        return (
          <Pagina1
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
          />
        )
      // mountOnEnter unmountOnExit
      case 2:
        return (
          <Slide direction="down" in={this.checked} >
            <Pagina2
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              values={values}
              setLojat={this.setLoja}
              handleChange={this.handleChange}
              updateState={this.updateState}
              setInput={this.setInput}
            />
          </Slide>
        )
      case 3:
        return (
          <Pagina3
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 4:
        return (
          <Pagina4
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 5:
        return (
          <Pagina5
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 6:
        return (
          <Pagina6
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 7:
        return (
          <Pagina7
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 8:
        return (
          <Pagina8
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            setInput={this.setInput}
          />
        )
      case 9:
        return (
          <Pagina9
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleClick={this.handleClick}
            setInput={this.setInput}
          />
        )
      case 10:
        return (
          <Pagina10
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleClick={this.handleClick}
            setInput={this.setInput}
          />
        )
      case 11:
        return (
          <Perguntas
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            handleChange={this.handleChange}
            close={this.close}
          />
        )
      // case 3: 
      //     return (
      //       <Confirmation 
      //         prevStep={ this.prevStep }
      //         nextStep={ this.nextStep }
      //         values={ values }
      //       />
      //     )
      //   case 4: 
      //     return (
      //       <Success />
      //     )
      default:
      // do nothing
    }
  }
}