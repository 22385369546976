
import React, { Component, useState } from 'react'
import { Button } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { api } from '../services/api';
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import '../assets/estilos/pags.css'
import '../assets/estilos/perguntas.css'
// import '../assets/estilos/bootstrap.min.css'
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdDone } from 'react-icons/md';
import Slide from '@material-ui/core/Slide';
import SelectInput from '@material-ui/core/Select/SelectInput';
import spiner from '../assets/img/loading-buffering.gif'

// import SelectComponent from './SelectComponent'

export default class Home extends Component {
	state = {
		tela: 0,
		animation: 'up',
		isFocus: false,
		input: '',
		idx: 0,
		btnIdx: -1,
		texto: '',
		respostas: [

		],

		perguntas: [
			{
				formatoResposta: 'Loading',
				opcoes: [],
				pergunta: '',
			},
			{
				formatoResposta: 'Escala',
				opcoes: [],
				pergunta: '',

			},
			{
				formatoResposta: 'Multipla Escolha',
				opcoes: [],
				pergunta: '',

			},
			{
				formatoResposta: 'Dissertativa',
				opcoes: [],
				pergunta: '',

			}
		]
	}
	constructor(props) {
		super()
		console.log(props);
		this.values = props.values
		console.log('1', this.values);
		this.prevStep = props.prevStep
		this.close = props.close
		this.input = React.createRef();
	}
	handleClick = (value, input) => {
		console.log(value);
		console.log(input);
	}

	handleChange(idx, event) {
		const { perguntas } = this.state
		perguntas[idx].resposta = event.target.value
		console.log(perguntas);
		console.log(event.target.value);
		console.log(idx);
		console.log(this.state);
		this.setState({ perguntas: perguntas });
		console.log(this.state);
	}
	handleChange2 = (idxPergunta, idxOpt, opt) => {
		// console.log(this.state);
		const { perguntas } = this.state
		// console.log(perguntas[idxPergunta]);
		perguntas[idxPergunta].opcoes[idxOpt].select = !perguntas[idxPergunta].opcoes[idxOpt].select
		var selecao = perguntas[idxPergunta].select
		// if (perguntas[idxPergunta].opcoes.filter(e => e.select == true).length > perguntas[idxPergunta].selecao) {
		// 	alert(`Só pode selecionar ${perguntas[idxPergunta].selecao} itens`)
		// 	return
		// } else {
		// }
		this.setState({ perguntas: perguntas });
		console.log(perguntas[idxPergunta]);
	}
	handleChange2t = (value, pergunta) => {
		const { perguntas } = this.state

		// console.log(value);
		// console.log(pergunta);
		// var arr = this.state.respostas
		// var idx = arr.findIndex(e => e.pergunta == pergunta)
		// if (idx < 0) {
		// 	arr.push({ pergunta: pergunta, resposta: [value] })
		// }
		// if (idx >= 0) {
		// 	var idx2 = arr[idx].resposta.findIndex(e => e == value)
		// 	if (idx2 < 0) {
		// 		arr[idx].resposta.push(value)
		// 	}
		// 	if (idx2 >= 0) {
		// 		arr[idx].resposta.splice(idx2, 1)
		// 	}
		// }
		// this.setState({ respostas: arr })
		// console.log(this.state);
	}
	handleCheckboxChange = (checked) => {
		console.log(checked);
	}

	handleButtonClick(anim) {
		// console.log(anim);
		const { idx, animation } = this.state;
		// console.log(idx);
		if (idx - 1 < 0) {
			console.log('-----------------------------------------------------------------------------');
			this.prevStep()
			return
		}
		else if (anim == 'down') {
			this.setState({ idx: idx - 1, animation: 'down' });
		} else {
			this.setState({ idx: idx + 1, animation: 'up' });
		}
		// this.render();
	}
	componentDidMount() {
		console.log('2', this.values)
		var windowWidth = window.innerWidth;
		console.log(windowWidth);
		this.setState({ tela: windowWidth })
		const headers = { headers: { 'Content-Type': 'application/json' } }
		const body = {
			url: 'http://barbosa.ibrs.com.br:5043/api/avaliacao/getavaliacao',
			search: window.location.search ? window.location.search : '',
			app_key: '0fmSSgUn63kQzjuDACeXA2D2',
			app_secret: 'n5nJxRfS4ydZ'
		}
		api.post('/', body, headers)
			.then(res => {
				const { data } = res
				console.log('data');
				console.log(data);

				console.log('lestPesquisa',data);
				const lestPesquisa = data.avaliacao
				var tipoPersona = ''
				console.log(lestPesquisa);
				if (this.values.escala <= 6) {
					tipoPersona = 'Detrator'
					this.setState({ tipoPersona: 'Detrator' })
					var perguntas = lestPesquisa.perguntas.filter(e => e.personas == 'Neutros' || e.personas == 'Detratores' || e.personas == 'Todos')
				}
				if (this.values.escala >= 7 && this.values.escala <= 8) {
					tipoPersona = 'Neutro'
					this.setState({ tipoPersona: 'Neutro' })

					var perguntas = lestPesquisa.perguntas.filter(e => e.personas == 'Neutros' || e.personas == 'Detratores' || e.personas == 'Todos')
				}
				if (this.values.escala >= 9) {
					tipoPersona = 'Promotor'
					this.setState({ tipoPersona: 'Promotor' })

					var perguntas = lestPesquisa.perguntas.filter(e => e.personas == 'Promotores' || e.personas == 'Todos')
				}
				var perguntasFormadas = []
				perguntas.map(e => {
					if (e.formatoResposta == "Dissertativa") {
						e.resposta = ''
					}
					if (e.formatoResposta == "Multipla Escolha") {
						// e.toselect = 
						e.select = false
					}
					perguntasFormadas.push(e, { formatoResposta: 'Padrao' })
				})
				perguntasFormadas.push({ formatoResposta: 'Observacao' }, { formatoResposta: 'Final' })
				this.setState({ perguntas: perguntasFormadas, idPesquisa: lestPesquisa._id })
				console.log(perguntasFormadas);
				console.log(this.state);
				console.log(this.values)
			})
			.catch(error => {
				console.log(error);
			})
	}
	finish() {
		var respostas = []
		// console.log(this.values);
		// console.log(this.state);
		this.state.perguntas.map(e => {
			var obj = {
				formatoResposta: '',
				pergunta: '',
				respostas: ''
			}
			if (e.formatoResposta == "Dissertativa") {
				obj.formatoResposta = e.formatoResposta
				obj.pergunta = e.pergunta
				obj.respostas = e.resposta
				respostas.push(obj)
			}
			if (e.formatoResposta == "Multipla Escolha") {
				obj.formatoResposta = e.formatoResposta
				obj.pergunta = e.pergunta
				obj.respostas = e.opcoes
				respostas.push(obj)
			}
			if (e.formatoResposta == "Escala") {
				obj.formatoResposta = e.formatoResposta
				obj.pergunta = e.pergunta
				obj.respostas = e.resposta
				respostas.push(obj)
			}
			if (e.formatoResposta == "Observacao") {
				obj.formatoResposta = e.formatoResposta
				obj.pergunta = 'Observação'
				obj.respostas = e.resposta
				respostas.push(obj)
			}
		})
		delete this.values.animation
		delete this.values.window
		// console.log(this.state);
		var tempo = new Date()
		var obj = {
			data: `${tempo.getDate()}/${tempo.getMonth() + 1}/${tempo.getFullYear()} - ${tempo.getHours()}:${tempo.getMinutes()}h`,
			idPesquisa: this.state.idPesquisa,
			ativo: true,
			pesquisa: respostas,
			persona: this.state.tipoPersona,
			cpf: window.location.search.split('?desligamento=')[1] ? window.location.search.split('?desligamento=')[1] : '',
			valores: this.values
		}
		// if (window.location.search.split('?desligamento=')[1]) {
		// 	obj.cpf = window.location.search.split('?desligamento=')[1]
		// }

		// console.log('obj: ', obj);
		// return
		const headers = { headers: { 'Content-Type': 'application/json' } }
		const body = {
			url: 'http://barbosa.ibrs.com.br:5043/api/avaliacao/insertpesquisa',
			app_key: '0fmSSgUn63kQzjuDACeXA2D2',
			app_secret: 'n5nJxRfS4ydZ',
			data: obj
		}
		console.log('pre-post');
		api.post('/', body, headers)
			.then(response => {
				console.log('response insertpesquisa', response);
				if (response.status == 200) {
					alert('Finalizado')
					window.open(window.location, '_self').close();
					window.location.href = "about:blank";
					window.close();
				} else {
					alert(`${response.data.msg}. Tente acessar link da pesquisa novamente.`)
				}
			})
		// .catch(error => console.log(error))
	}
	Previous = () => {
		const { idx, animation } = this.state;
		if (idx - 1 < 0) {
			console.log('-----------------------------------------------------------------------------');
			this.prevStep()
			return
		}
		this.setState({ animation: 'down', idx: idx - 1 })
		console.log('previous: ', this.state);
	}
	Continue = (pergunta) => {
		console.error('Pergunta >>', pergunta);

		if (pergunta.formatoResposta == "Multipla Escolha") {
			console.log(pergunta);
			if (pergunta.opcoes.filter(e => e.select == true).length < 1) {
				alert(`Selecione ao menos uma alternativa`)
				return
			}
			if (pergunta.opcoes.filter(e => e.select == true).length > pergunta.selecao) {
				alert(`Só pode selecionar ${pergunta.selecao} itens`)
				return
			}
		}
		if (pergunta.formatoResposta == "Escala") {
			// console.log(pergunta);
			if (!pergunta.resposta) {
				alert(`Selecione uma escala`)
				return
			}
		}
		if (pergunta.formatoResposta == "Dissertativa") {
			if (!pergunta.resposta || pergunta.resposta == '') {
				alert(`Adicione sua resposta`)
				return
			}
		}
		const { idx, animation } = this.state;
		this.setState({ idx: idx + 1, animation: 'up' });
		// console.log('previous: ', this.state);
		this.render()
	}
	onInputchange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}
	next = () => {
		const { idx } = this.state;
		this.setState({ idx: idx + 1 });
		console.log(this.values);
		console.log('next: ', this.state);
	}
	getData = (resposta, pergunta) => {
		console.log(resposta);
		console.log(pergunta);

		var arr = this.state.respostas
		var idx = arr.findIndex(e => e.pergunta == pergunta)
		if (idx < 0) {
			arr.push({ pergunta: pergunta, resposta: resposta })
		} else {
			arr[idx].resposta = resposta
		}
		this.setState({ respostas: arr })
		console.log(resposta);
		console.log(pergunta);
		console.log(this.state);
		this.next()
	}
	onAddingItem = (i) => (event) => {
		this.setState((state, props) => {
			state.productsList[i].isChecked = !state.productsList[i].isChecked;
			return {
				productsList: state.productsList
			}
		})
	}
	setInput = (input) => {
		// console.log(input);
	}
	handleInput = (isFocus) => {
		// console.log(isFocus);
		setTimeout(() => {
			this.setState({ isFocus: isFocus })
			// setIsFocus(isFocus);
		}, 250)
	}
	inputFun = (idx, pergunta, idxPergunta) => {
		const newPergunta = this.state.perguntas
		newPergunta[idxPergunta].resposta = idx + ''
		this.setState({ btnIdx: idx, perguntas: newPergunta })
		// console.log(this.state);
	}
	render() {
		// console.log(this.state.perguntas[this.state.idx])
		switch (this.state.perguntas[this.state.idx].formatoResposta) {
			case 'Loading':
				return (
					<div className="loading">
						<img width='64' src={spiner} />
					</div>
				)
			case 'Escala':
				return (
					<Slide direction={this.state.animation} in={true}>
						<div className="container">
							<div className="box">
								<div className="titulo">
									{this.state.perguntas[this.state.idx].pergunta}
								</div>
								{/* values.window <= 500 ? "escala-grid" : (values.window > 500 && values.window <= 800) ? "escala-tablet" : "escala" */}
								{/* <div className={(this.state.tela < 500) ? "escala-grid" : "escala"}> */}
								<div className={this.state.tela <= 500 ? "escala-grid" : (this.state.tela > 500 && this.state.tela <= 800) ? "escala-tablet" : "escala"}>
									{this.state.perguntas[this.state.idx].opcoes.map((e, i) => {
										// console.log(this.state.perguntas[this.state.idx]);
										// return <div className={i != this.state.btnIdx ? "button-escala" : "button-escala select-escala"} value={e.valor} onClick={() => this.inputFun(i, this.state.perguntas[this.state.idx], this.state.idx)} key={i} >{e}</div>
										return <div className={i + '' != this.state.perguntas[this.state.idx].resposta ? "button-escala" : "button-escala select-escala"} value={e.valor} onClick={() => this.inputFun(i, this.state.perguntas[this.state.idx], this.state.idx)} key={i} >{e}</div>
									})}
								</div>
								{this.state.tela > 800 ? <div className="labels">
									<div className="label-left">Pouco satisfeito</div>
									<div className="label-right">Muito satisfeito</div>
								</div> :
									<div className="labels-mobile-line">
										<div className="label-left-mobile">Pouco satisfeito</div>
										<div className="label-right-mobile">Muito satisfeito</div>
									</div>
									//  <div className="labels-mobile">
									// 	<div className="label-left">Pouco satisfeito - {this.state.perguntas[this.state.idx].opcoes.length[0]} a {this.state.perguntas[this.state.idx].opcoes.length[0]}</div>
									// 	<div className="label-right">Muito satisfeito - {this.state.perguntas[this.state.idx].opcoes.length - 2} a {this.state.perguntas[this.state.idx].opcoes.length - 1}	</div>
									// </div>
								}

								{/* <button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="btn">Ok <MdDone className="iconStyles" /></button> */}
								<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="btn">Ok <MdDone className="iconStyles" /></button>
								<div className="subbuttons">
									<button onClick={this.Previous} className="up"><MdKeyboardArrowUp color="#fff" size={30} className="iconStyles-2" /></button>
									<div className="middle"></div>
									<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="down"><MdKeyboardArrowDown color="#fff" size={30} /></button>
								</div>
							</div>
							{/* <Button onClick={this.Continue} style={styles.btn}>Ok</Button> */}
						</div>
					</Slide>
				)
			case 'Multipla Escolha':
				console.log(this.state.perguntas[this.state.idx].opcoes)
				return (
					<Slide direction={this.state.animation} in={true}>
						<div className="container-scroll">
							<div className="box-scroll">
								{this.state.tela > 500 ? <div className="titulo-scroll">
									{this.state.perguntas[this.state.idx].pergunta}
								</div> : null}
								<div className='wrapper'>
									{this.state.tela < 500 ? <div className="titulo-scroll">
										{this.state.perguntas[this.state.idx].pergunta}
									</div> : null}
									{this.state.perguntas[this.state.idx].opcoes.map((e, i) => {
										return (
											<div className="valorChck" key={i}>
												<label className={(i % 2 != 0 && this.state.tela > 500) ? 'label-check' : ''}>
													<Checkbox
														icon={<Icon.FiCheck color="#fff" size={25} />}
														borderColor="#A6ACCD"
														label={e.opcao}
														style={styles.check}
														checked={this.state.perguntas[this.state.idx].opcoes[i].select}
														labelStyle={{ marginLeft: 10, userSelect: "none" }}
														onChange={() => this.handleChange2(this.state.idx, i, e)}
													/>
													<span className="textoCheck">{e.legenda}</span>
												</label>
											</div>
										)
									})}
								</div>
								<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="btn">Ok <MdDone className="iconStyles" /></button>
								<div className={this.state.tela > 500 ? "subbuttons" : "subbutons-wrapper"}>
									<button onClick={this.Previous} className="up"><MdKeyboardArrowUp color="#fff" size={30} className="iconStyles-2" /></button>
									<div className="middle"></div>
									<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="down"><MdKeyboardArrowDown color="#fff" size={30} /></button>
								</div>

							</div>
						</div>
					</Slide>
				)
			case 'Dissertativa':
				return (
					<Slide direction={this.state.animation} in={true}>
						<div className="container">
							<div className="box">
								<div className="titulo">
									{this.state.perguntas[this.state.idx].pergunta}
								</div>
								<input
									type="text"
									value={this.state.perguntas[this.state.idx].resposta}
									className={this.state.perguntas[this.state.idx].resposta == '' ? 'myInput' : 'myInput-select'}
									placeholder="Digite sua resposta"
									// onFocus={() => { this.handleInput(true) }}
									// onBlur={() => { this.handleInput(false) }}
									onChange={(e) => this.handleChange(this.state.idx, e)}
								// onBlur={() => this.setInput(this.state.perguntas[this.state.idx].resposta)}
								/>
								<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="btn">Ok <MdDone className="iconStyles" /></button>
								<div className="subbuttons">
									<button onClick={this.Previous} className="up"><MdKeyboardArrowUp color="#fff" size={30} className="iconStyles-2" /></button>
									<div className="middle"></div>
									<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="down"><MdKeyboardArrowDown color="#fff" size={30} /></button>
								</div>
							</div>
						</div>
					</Slide>
				)
			case 'Observacao':
				return (
					<Slide direction={this.state.animation} in={true}>
						<div className="container">
							<div className="box">
								<div className="titulo">
									Faça suas considerações com relação á sua jornada na família Barbosa.
								</div>
								<div className="text-area">
									<textarea
										type="text"
										maxlength="100"
										value={this.state.perguntas[this.state.idx].resposta}
										// className={this.state.perguntas[this.state.idx].resposta == '' ? 'myTextArea' : 'myInput-select'}
										className={'myTextArea'}
										placeholder="Digite sua observação"
										onChange={(e) => this.handleChange(this.state.idx, e)}
									/>
								</div>
								<div className='texto-text-area'>
									{this.state.perguntas[this.state.idx].resposta ? this.state.perguntas[this.state.idx].resposta.length : 0}/100
								</div>
								<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="btn">Ok <MdDone className="iconStyles" /></button>
								<div className="subbuttons">
									<button onClick={this.Previous} className="up"><MdKeyboardArrowUp color="#fff" size={30} className="iconStyles-2" /></button>
									<div className="middle"></div>
									<button onClick={() => this.Continue(this.state.perguntas[this.state.idx])} className="down"><MdKeyboardArrowDown color="#fff" size={30} /></button>
								</div>
							</div>
						</div>
					</Slide>
				)
			case 'Final':
				return (
					<Slide direction={this.state.animation} in={true}>
						<div className="container">
							<div className="box">
								<div className="titulo">
									Agradecemos por dedicar seu tempo para responder a pesquisa Conta pra Gente.
									{/* Obrigado Por dedicar seu tempo para responder nossa pesquisa */}
								</div>
								<button onClick={() => this.finish()} className="btn-enviar"><div className="elements">Enviar</div></button>



							</div>
						</div>
					</Slide>
				)
			case 'Padrao':
				this.handleButtonClick(this.state.animation)
				return (
					<div>

					</div>
				)


		}

	}
}

const styles = {
	textoCheck: { marginLeft: "1.6rem", fontFamily: "'Montserrat', sans-serif", fontSize: '1.2rem', opacity: '0.6' },
	check: {
		cursor: "pointer",
		// justifyContent: space-between,
		display: "inline-block",
		width: "16px",
		height: "26px",
		backgroundColor: "#202E84",
		// background: ${props => (props.checked ? 'salmon' : 'papayawhip')}
		borderRadius: "3px",
		// transition: all 150ms;
	},
	wrapper: {
		justifyContent: " space-between",
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "10px",
		gridAutoRows: "100px",
	},
	paper: {
		padding: "1rem",
		textAlign: "center",
		color: "red",
	},
	btni: {
		hover: "red",
		width: "3rem",
		height: "3rem",
		marginTop: "2%",
		color: "#fff",
		fontSize: "17pt",
		border: "1px solid #A6ACCD",
		margin: "0.5%"
	},
	formControl: {
		width: "100%",
		color: "#FFFFFF"
	},
	input: {
		color: "#FFFFFF",
		fontSize: "1.5rem"
	},
	input2: {
		fontFamily: "'Montserrat', sans-serif",
		fontSize: "1.5rem"
	},
	valorChck: {
		fontFamily: "'Montserrat', sans-serif",
		fontSize: "1.5rem",
		padding: "5%",
		// marginLeft: "22%",
		color: "#fff"
	},
	select: {
		width: "100%"
	},
	escala: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "spaceBetween"
	},
	container: {
		width: "100vw",
		height: "100vh",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		fontFamily: "'Montserrat', sans-serif"
	},
	box: {
		marginBottom: "17%",
		width: "48vw",
		height: "30vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "left",
	},
	box2: {
		// marginBottom: "17%",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	sub: {
		fontFamily: "'Montserrat', sans-serif",
		color: "#fff",
		width: "100%",
		display: "flex",
		flexDirection: "row",
	},
	esquerda: {
		float: "left"
	},
	direita: {
		marginLeft: "90%"
		// float:"right"
	},
	titulo: {
		fontSize: "20pt",
		marginBottom: "3%",
		marginRight: "11%",
		color: '#fff'
	},
	logo: {
		maxWidth: "200px",
		maxHeight: "150px"
	},
	texto: {
		font: "normal normal medium 20px/28px Montserrat",
		color: "#FFFFFF",
		// letterSpacing: "1px",
		textAlign: "center",
		marginTop: "3%",
		color: "#fff",
		fontSize: "1rem",
		opacity: "0.65"
	},
	btn: {
		width: "6rem",
		height: "3rem",
		marginTop: "2%",
		color: "#fff",
		backgroundColor: "#E9732F",
		fontSize: "17pt",
	},
	btni: {
		hover: "red",
		width: "3rem",
		height: "3rem",
		marginTop: "2%",
		color: "#fff",
		fontSize: "17pt",
		border: "1px solid #A6ACCD",
		margin: "0.5%"
	}

}

